import './index.css';

import React, { useState } from "react";
import Logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import WalletConnect from '../ConnectWallet';
import { useAppState } from '../../provider/AppContext';

function Header(props) {
  const {theme, setTheme} = useAppState();
  const [active, setActive] = useState(() => {
    const url = window.location.href;

    if(url.includes('/swap')) return 2;
    if(url.includes('/token')) return 3;
    if(url.includes('/staking')) return 4;

    return 1;
  });

  return (
    <header>
      <div className="tcd-header-left">
        <a href="/">
          <img src={Logo} alt="logo" className="logo" />
        </a>
      </div>
      <div className={`tcd-main-menu`}>
        <Link to="/" className={`link ${active === 1 ? 'active' : ''}`} onClick={() => setActive(1)}>
          <div className="headerItem">Home</div>
        </Link>
        <Link to="/swap" className={`link ${active === 2 ? 'active' : ''}`} onClick={() => setActive(2)}>
          <div className="headerItem">Swap</div>
        </Link>
        <Link to="/token" className={`link ${active === 3 ? 'active' : ''}`} onClick={() => setActive(3)}>
          <div className="headerItem">Tokens</div>
        </Link>
        {/* <Link to="/staking" className={`link ${active === 4 ? 'active' : ''}`} onClick={() => setActive(4)}>
          <div className="headerItem">Staking</div>
        </Link> */}
      </div>
      <div className="tcd-header-right">
        {active === 2 ? '' :<WalletConnect /> }
      </div>
    </header>
  );
}

export default Header;
