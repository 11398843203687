import './index.css';
import imgAvadex from '../../images2/avadex.png';
import imgUniswapEx from '../../images2/uniswap-ex.png';
import imgEthChain from '../../images2/ethchain.png';
import imgEthPng from '../../images2/eth.png';
import imgCg from '../../images2/cg.png';
import imgCmc from '../../images2/cmc.png';
import imgDextools from '../../images2/dextools.png';
import imgDexview from '../../images2/dexview.png';
import imglogo from '../../images2/logo.png';
import imgPinkSale from '../../images2/pinksale.png';
import imgTelegram from '../../images2/telegram.svg';
import imgWallet from '../../images2/wallet.svg';
import imgX from '../../images2/x.svg';
import { Chart, ArcElement } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { LINK_TELE, LINK_X } from '../../constant';
import imgCz from '../../images2/cz.png';
Chart.register(ArcElement);

const Home = () => {

  const percents = [5, 5, 5, 25, 25, 30];
  const colors = [
    '#f44336',
    '#01a9f4',
    '#9c27b0',
    '#4050b5',
    '#e91e63',
    '#673ab7',
  ];
  const labels = [
    'Dev (lock 90%)',
    'Marketing (unlock 100%)',
    'Presale (unlock 100%)',
    'Staking',
    'Burn',
    'Public sale',
  ]

  const data = {
    labels: labels,
    datasets: [{
      label: 'Tokenomics',
      data: percents,
      backgroundColor: colors,
      hoverOffset: 10
    }]
  }

  const options = {
    title: {
      display: true,
      text: "World Wide Wine Production 2018"
    }
  }

  const renderLegend = () => {
    return percents.map((item, index) => {
      return <div className="col-lg-4 col-md-4 col-sm-4">
        <div className="legend-item">
          <span style={{ background: colors[index]}} className="legend-color"></span>
          <span>{`${labels[index]} ${percents[index]}%`}</span>
        </div>
      </div>
    })
  }

  const renderTitle = (title) => {
    return <div className="row text-center justify-content-center">
      <div className="col-lg-12 mt-5 mb-5">
        <h2>{title}</h2>
      </div>
    </div>
  }

  return <div className="cocox-home">

    {/* Introducing  */}
    <section className="container">
      {renderTitle('I Love Binance')}
      <div className=" row justify-content-center align-content-center align-items-center text-center">
        <div className='col-lg-6 col-sm-12'>
          <img class="img-fluid" alt="" src={imgCz} />
        </div>
        <div className="col-lg-6 col-sm-12 text-left">
          <p>Welcome to CocoX, your gateway to a cutting-edge decentralized financial ecosystem! CocoX goes beyond being just a token; it's a comprehensive platform that offers both swift swapping and staking features, boasting an impressive Annual Percentage Rate (APR) of 100%.</p>
          <p>Welcome to CocoX, your gateway to a cutting-edge decentralized financial ecosystem! CocoX goes beyond being just a token; it's a comprehensive platform that offers both swift swapping and staking features, boasting an impressive Annual Percentage Rate (APR) of 100%.</p>
          <p>Welcome to CocoX, your gateway to a cutting-edge decentralized financial ecosystem! CocoX goes beyond being just a token; it's a comprehensive platform that offers both swift swapping and staking features, boasting an impressive Annual Percentage Rate (APR) of 100%.</p>
        </div>
      </div>
    </section>

    {/* How to buy */}
    <section className="container-fluid ">
      <div className="container">
        {renderTitle('How to buy')}
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="card mb-3">
              <div className="row g-3 p-3">
                <div className="col-lg-2 col-sm-12"><img src={imgWallet} alt="" className="img-fluid rounded-start" /></div>
                <div className="col-lg-10 col-sm-12">
                  <div className="card-body">
                    <h2 className="card-title">Step 1: Create a Wallet</h2>
                    <p className="card-text">Download metamask or your wallet of choice from the app store or google play
                      store for free. Desktop users, download the google chrome extension by going to <a target='_blank' rel="noreferrer" className='text-white' href="https://metamask.io/download/">metamask.io</a>.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="card mb-3">
              <div className="row g-3 p-3">
                <div className="col-lg-2 col-sm-12"><img src={imgEthPng} alt="" className="img-fluid rounded-start" /></div>
                <div className="col-md-10 col-sm-12">
                  <div className="card-body">
                    <h2 className="card-title">Step 2: Get some ETH</h2>
                    <p className="card-text">Have ETH in your wallet to switch to $CocoX. If you don’t have any ETH, you
                      can buy directly on metamask, transfer from another wallet, or buy on another exchange and send it
                      to your wallet.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="card mb-3">
              <div className="row g-3 p-3">
                <div className="col-lg-2 col-sm-12"><img src={imglogo} alt="" className="img-fluid rounded-start"/></div>
                <div className="col-lg-10 col-sm-12">
                  <div className="card-body">
                    <h2 className="card-title">Step 3: Use Coco Swap</h2>
                    <p className="card-text">Connect to Cocox App. Go to tab Swap in google chrome or on the
                      browser inside your Metamask app. Connect your wallet. Paste the $CocoX token address into
                      select token, select CocoX, and confirm. When Metamask prompts you for a wallet signature, sign.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="card">
              <div className="row g-3 p-3">
                <div className="col-lg-2 col-sm-12"><img src={imglogo} alt="" className="img-fluid rounded-start" /></div>
                <div className="col-lg-10 col-sm-12">
                  <div className="card-body">
                    <h2 className="card-title">Step 4: Switch ETH for $CocoX</h2>
                    <p className="card-text">Switch ETH for $CocoX. We have ZERO taxes so you don’t need to worry about
                      buying with a specific slippage, although you may need to use slippage during times of market
                      volatility. Note: Additionally, you can also use uniswap for step 3</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* Roadmap */}
    <section className="container-fluid ">
      <div className="container">
        {renderTitle('Roadmap')}
        {/* <p className="text-white-50 ">All jokes aside, here is a rough sketch of $CocoX path ahead. We dont wan’t
              to give everything away on day 1, Expect surprises along the way 😉</p> */}
        <div className="row ">
          <div className="col-lg-4 mb-3">
            <div className="road">
              <h3>Pharse 1</h3>
              <ul className="list-unstyled">
                <li>Launch</li>
                <li>CG / CMC Listings</li>
                <li>1,000+ Holders</li>
                <li>Get $CocoX Trending on twitter with our memetic power</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 mb-3">
            <div className="road">
              <h3>Pharse 2</h3>
              <ul className="list-unstyled">
                <li>Staking</li>
                <li>Community Partnerships</li>
                <li>CEX Listings</li>
                <li>10,000+holders</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 mb-3">
            <div className="road">
              <h3>Pharse 3</h3>
              <ul className="list-unstyled">
                <li>CocoX</li>
                <li>T1 Exchange Listings</li>
                <li>100,000+ holders</li>
                <li>Meme Takeover</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* Tokenomics */}
    <section className="container-fluid ">
      <div className="container">
        {renderTitle('Tokenomics')}
        <div className="row  justify-content-md-center">
          <div classname="col-lg-4 col-sm-12">
            <Pie data={data} options={options}/>
          </div>
        </div>
        <div className="row mt-4 text-center justify-content-center">
          {renderLegend()}
        </div>
      </div>
    </section>
    
    {/* Partners */}
    <section className="container-fluid ">
      <div className="container">
        {renderTitle('Partners')}

        <div className="row">
          <div className="col-lg-3 col-6 mb-3"><img src={imgEthChain} alt="" className="img-fluid rounded-start" /></div>
          <div className="col-lg-3 col-6 mb-3"><img src={imgPinkSale} alt="" className="img-fluid rounded-start" /></div>
          <div className="col-lg-3 col-6 mb-3"><img src={imgDexview} alt="" className="img-fluid rounded-start" /></div>
          <div className="col-lg-3 col-6 mb-3"><img src={imgCmc} alt="" className="img-fluid rounded-start" /></div>
          <div className="col-lg-3 col-6 mb-3"><img src={imgCg} alt="" className="img-fluid rounded-start" /></div>
          <div className="col-lg-3 col-6 mb-3"><img src={imgDextools} alt="" className="img-fluid rounded-start" /></div>
          <div className="col-lg-3 col-6 mb-3"><img src={imgUniswapEx} alt="" className="img-fluid rounded-start" /></div>
          <div className="col-lg-3 col-6 mb-3"><img src={imgAvadex} alt="" className="img-fluid rounded-start" /></div>
        </div>
        <div className="row pt-5 justify-content-center text-center">
          <div className="col-lg-6">
            <p>Join the exciting new community of CocoX, where refreshment meets crypto ownership and fun awaits!</p>
            <ul className="list-inline social ">
              <a target="_blank" href={LINK_X} rel="noreferrer">
                <img src={imgX} alt="" />
              </a>
              <a href={LINK_TELE} target="_blank" rel="noreferrer">
                <img src={imgTelegram} alt="" />
              </a>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
}

export default Home;