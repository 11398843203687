import { useEffect, useState } from 'react';
import './index.css';
import { TOKEN_TOP_100 } from '../../constant';

function Tokens() {
  const [tokens, setTokens] = useState([]);
  const [tokensTop100, setTokensTop100] = useState([]);
  const [tokensTopGainer, setTokensTopGainer] = useState([]);
  const [tokensTopLoser, setTokensTopLoser] = useState([]);
  const [buttonActive, setButtonActive] = useState('top-100');
  const [topToken100] = useState(TOKEN_TOP_100)
  const isMobileScreen = window.screen.width < 768 || window.innerWidth < 768;


  const formatPrice = (price) => {
    if (price > 0.01) return price.toFixed(2);

    return price;
  }

  const getChangePrice = (price, historyPrice, key) => {
    if (!price || !historyPrice[key]?.USD) return '';

    const percentChange = ((price - historyPrice[key].USD) / Math.max(price, historyPrice[key].USD)) * 100;

    return <span className={percentChange > 0 ? 'color-green' : 'color-red'}>{percentChange.toFixed(2) + '%'}</span>
  }

  const formatPipRange = (value) => {
    const ranges = [
      { node: 1e3, symbol: 'K', fix: 0, suffix: true },
      { node: 1e6, symbol: 'M', fix: 2, suffix: true },
      { node: 1e9, symbol: 'B', fix: 2, suffix: true }, // add new - not in requirement
      // { node: 1e12, symbol: 'T', fix: 2, suffix: false }, // add new - not in requirement
      // { node: 1e18, symbol: 'E', fix: 2, suffix: false }, // add new - not in requirement
    ];

    if (Array.isArray(ranges) && ranges.length > 0) {
      let dividedValue = '0';
      let remainderValue = '';

      for (let i = ranges.length - 1; i >= 0; i--) {
        const range = ranges[i];
        if (value >= range.node) {
          dividedValue = Math.floor(value / range.node).toString();
          // parseInt -> remove composite number
          const valStr = parseInt('' + value).toString(),
            nodeStr = range.node.toString();
          const _start = valStr.length - nodeStr.length + 1; // Start from break node
          remainderValue = valStr.substring(_start, _start + range.fix);

          return dividedValue + (remainderValue ? '.' + remainderValue : '') + range.symbol;
        }
      }
    }

    return value;
  }

  const getToken = (topToken100) => {
    fetch(`https://api.cryptorank.io/v0/coins?keys=${topToken100}`).then(response => response.json())
      .then(response => {
        setTokens(response.data || []);
        setTokensTop100(response.data);
      }).catch(e => {
        console.log("e", e);
      });
  }

  const getTopGainer = () => {
    fetch(`https://api.cryptorank.io/v0/coins?specialFilter=topGainersFor24h&limit=150&locale=en`).then(response => response.json())
      .then(response => {
        setTokensTopGainer(response.data || []);
      }).catch(e => {
        console.log("e", e);
      });
  }

  const getTopLoser = () => {
    fetch(`https://api.cryptorank.io/v0/coins?specialFilter=topLosersFor24h&limit=150&locale=en`).then(response => response.json())
      .then(response => {
        setTokensTopLoser(response.data || []);
      }).catch(e => {
        console.log("e", e);
      });
  }

  useEffect(() => {
    getToken(topToken100);
    getTopGainer();
    getTopLoser();
  }, [topToken100]);

  const PlaceHolderTable = () => {
    let numberRow = 100;
    let numberCell = 12;
    if(isMobileScreen) {
      numberCell = 4;
    }

    return <tbody>
      {Array(numberRow).fill({place: 'row'}).map((row, i) => {
        return <tr key={`${row}-${i}`}>
          {Array(numberCell).fill({place: 'cell'}).map((cell,j) => {
            return <td key={`${cell}-${i}-c${j}`} className='tcd-cell'>
              <div className='tcd-placeholder-cell'></div>
            </td>
          })}
        </tr>
      })}
    </tbody>
  }

  const RenderContentTable = () => {
    return <tbody>
      {tokens.map((item, i) => {
        return <tr key={i} className='tcd-row'>
          <td className={`tcd-cell text-center ${buttonActive !== 'top-100' ? 'mobile-hide' : ''}`}>
            <span className='tcd-token-rank'>{item.rank || '...'}</span>
          </td>
          <td className='tcd-cell text-left'>
            <div className='center-content'>
              <img className='tcd-token-icon p-r-5' alt={item.name} src={item.image?.x60 || item.image?.icon || item.image?.native} />
              <span className='tcd-token-name'>{item.name}</span>
              <span className='tcd-token-symbol'>{item.symbol}</span>
            </div>
          </td>
          <td className='tcd-cell'>
            <span className='tcd-token-price'>${formatPrice(item.price.USD)}</span>
          </td>
          <td className='tcd-cell'>
            <span className='tcd-token-price'>{getChangePrice(item.price.USD, item.histPrices, '24H')}</span>
          </td>
          <td className='tcd-cell mobile-hide'>
            <span className='tcd-token-price'>{getChangePrice(item.price.USD, item.histPrices, '7D')}</span>
          </td>
          <td className='tcd-cell mobile-hide'>
            <span className='tcd-token-price'>{getChangePrice(item.price.USD, item.histPrices, '30D')}</span>
          </td>
          <td className='tcd-cell mobile-hide'>
            <span className='tcd-token-price'>{getChangePrice(item.price.USD, item.histPrices, '3M')}</span>
          </td>
          <td className='tcd-cell mobile-hide'>
            <span className='tcd-token-price'>{getChangePrice(item.price.USD, item.histPrices, '6M')}</span>
          </td>
          <td className='tcd-cell mobile-hide'>
            <span className='tcd-token-price'>{getChangePrice(item.price.USD, item.histPrices, '1Y')}</span>
          </td>
          <td className='tcd-cell mobile-hide'>
            <span className='tcd-token-vol'>{formatPipRange(item.volume24h * item.price.USD)}</span>
          </td>
          <td className='tcd-cell mobile-hide'>
            <span className='tcd-token-cap'>{formatPipRange(item.marketCap)}</span>
          </td>
          <td className='tcd-cell mobile-hide'>
            <span className='tcd-token-supply'>{formatPipRange(item.availableSupply)}</span>
          </td>
        </tr>
      })}
    </tbody>
  }

  return (
    <>
      <div className='tcd-button-top-container'>
        <button className={`tcd-button-top tcd-top-100 ${buttonActive === 'top-100' ? 'active' : '' }`} onClick={() => {
          if(tokens?.length === 0) return;
          setTokens(tokensTop100)
          setButtonActive('top-100');
        } }>Top 100</button>
        <button className={`tcd-button-top tcd-top-gainer ${buttonActive === 'top-gainer' ? 'active' : '' }`} onClick={() => {
          if(tokens?.length === 0) return;
          setButtonActive('top-gainer');
          setTokens(tokensTopGainer)
        }}>Gainer</button>
        <button className={`tcd-button-top tcd-top-loser ${buttonActive === 'top-loser' ? 'active' : '' }`} onClick={() => {
          if(tokens?.length === 0) return;
          setButtonActive('top-loser');
          setTokens(tokensTopLoser)
        }}>Loser</button>
      </div>
      <table className='tcd-table'>
        <thead>
          <tr>
            <td className={`text-center ${buttonActive !== 'top-100' ? 'mobile-hide' : ''}`}>#</td>
            <td className='text-center tcd-header-name'>Name</td>
            <td className='text-center tcd-header-price'>Price</td>
            <td className='text-center tcd-header-change'>Chg(24h)</td>
            <td className='text-center tcd-header-change mobile-hide'>Chg(7D)</td>
            <td className='text-center tcd-header-change mobile-hide'>Chg(30D)</td>
            <td className='text-center tcd-header-change mobile-hide'>Chg(3M)</td>
            <td className='text-center tcd-header-change mobile-hide'>Chg(6M)</td>
            <td className='text-center tcd-header-change mobile-hide'>Chg(1Y)</td>
            <td className='text-center tcd-header-vol mobile-hide'>Volume(24h)</td>
            <td className='text-center tcd-header-cap mobile-hide'>Market Cap</td>
            <td className='text-center tcd-header-supply mobile-hide'>Circ. Supply</td>
          </tr>
        </thead>
        {
          tokens?.length === 0 ? <PlaceHolderTable /> : <RenderContentTable />
        }
      </table>
    </>
  );
}

export default Tokens;
