import './index.css';
import {useAppState} from '../../provider/AppContext';
import logoMetamask from '../../images/metamask.png';
import avatarWallet from '../../images/avatarWallet.png';
import logoWalletConnect from '../../images/walletconnect.png';
import { useState } from 'react';

const  ConnectWallet = () => {
  const { setIsConnect, addressWallet  } = useAppState();
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [errorConnect, setErrorConnect] = useState({type: '', message: ''});


  const connectWalletMetamask = async () => {
    try {
      if (window.ethereum) {
        try {
          await window.ethereum.enable();
          setIsConnect(true);
          setIsShowPopup(false);
        } catch (error) {
          setErrorConnect({
            type: 'metamask',
            message: 'Người dùng từ chối kết nối ví MetaMask.'
          })
        }
      } else {
        setErrorConnect({
          type: 'metamask',
          message: 'Vui lòng cài đặt MetaMask để sử dụng ứng dụng này.'
        })
      }
    } catch (error) {
      console.error(error);
    }
    
  }

  const displayWallet = () => {
    if(!addressWallet) return '';

    return addressWallet.slice(0, 5) + '...' + addressWallet.slice(-4);

  }

  return <>
    { addressWallet ? <div className='tcdao-address-wallet'>
      <img className='tcdao-avatar-wallet' alt='avatar wallet' src={avatarWallet} />
      {displayWallet()}
      </div> : <button className="tcdao-btn tcdao-connectWallet" onClick={() => setIsShowPopup(true)}>
      Connect Wallet
    </button>}
    

    { isShowPopup && <div className='tcdao-popup-select-wallet-container'>
      <div className='tcdao-box tcdao-popup-select-wallet'>
        <button className='tcdao-close' onClick={()=>setIsShowPopup(false)}>
          <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_7619_12589)"><rect width="16" height="16" fill="none"></rect><path fillRule="evenodd" clipRule="evenodd" d="M15.5131 2.5999C16.099 2.01422 16.0992 1.06447 15.5135 0.478583C14.9278 -0.107303 13.9781 -0.107465 13.3922 0.478222L7.97633 5.89222L2.56048 0.478222C1.97459 -0.107465 1.02485 -0.107303 0.439159 0.478583C-0.146528 1.06447 -0.146366 2.01422 0.439521 2.5999L5.85465 8.01318L0.439521 13.4265C-0.146366 14.0121 -0.146528 14.9619 0.439159 15.5478C1.02485 16.1337 1.97459 16.1338 2.56048 15.5481L7.97633 10.1341L13.3922 15.5481C13.9781 16.1338 14.9278 16.1337 15.5135 15.5478C16.0992 14.9619 16.099 14.0121 15.5131 13.4265L10.098 8.01318L15.5131 2.5999Z" fill="currentColor"></path></g><defs><clipPath id="clip0_7619_12589"><rect width="16" height="16" fill="none"></rect></clipPath></defs></svg>
        </button>
        <h3 className='tcdao-center tcdao-title-connect'>Connect Wallet</h3>

        <div className='tcdao-connect-wallet' onClick={connectWalletMetamask} >
          <img alt='logo metamask' className='tcdao-logo-wallet' src={logoMetamask} />
          <span className='tcdao-connect-label'>Metamask</span>
        </div>
        <span className='tcdao-error-message'>{errorConnect.message}</span>
        <div className='tcdao-connect-wallet tcdao-connect-wallet-commingsoon' >
          <img alt='logo WalletConnect' className='tcdao-logo-wallet' src={logoWalletConnect} />
          <span className='tcdao-connect-label'>WalletConnect</span>
          <span className='tcdao-comming-soon'>coming soon</span>
        </div>
      </div>
    </div>}
  </>
}

export default  ConnectWallet;
